import Router from "next/router";
import NProgress from "nprogress";
import { NoAuthContent } from "../../styles/layout/contain";

Router.events.on("routeChangeStart", () => {
	NProgress.start();
});
Router.events.on("routeChangeComplete", () => {
	NProgress.done();
});
Router.events.on("routeChangeError", () => {
	NProgress.done();
});

const NoAuthLayout = ({ children = null, breadcrumb = [], hideNavigation = false }) => (
	<>
		<NoAuthContent breadcrumb={breadcrumb.length ? true : false}>{children}</NoAuthContent>
	</>
);

export default NoAuthLayout;
