import styled from 'styled-components';
import { RelSize } from '../mixins';

const LandingIconStyled = styled.div`
    display: block;
	flex: 1;
	position: relative;
    svg {
        display: block;
        height: 100%;
        width: 100%;
    }
	@media all and (max-width: 768px) {
		width: 100%;
	}
`;

export const LandingPagination = styled.div`
	bottom: ${RelSize(10, 16)};
	display: flex;
	left: ${RelSize(10, 16)};
	position: absolute;
	@media all and (max-width: 768px) {
		bottom: ${RelSize(5, 16)};
		left: ${RelSize(5, 16)};
	}
	> div {
		background-color: ${props => props.theme.primaryGreen};
		border: 5px solid transparent;
		border-radius: 50%;
		height: ${RelSize(25, 16)};
		width: ${RelSize(25, 16)};
		&:not(:last-child) {
			margin-right: ${RelSize(10, 16)};
		}
		${props => props.active === 0 && `
			&:nth-child(1) {
				border-color: #C5C8BC;
			}
		`}
		${props => props.active === 1 && `
			&:nth-child(2) {
				border-color: #C5C8BC;
			}
		`}
		${props => props.active === 2 && `
			&:nth-child(3) {
				border-color: #C5C8BC;
			}
		`}
		@media all and (max-width: 768px) {
			border-width: 2px;
			height: ${RelSize(15, 16)};
			width: ${RelSize(15, 16)};
		}
	}
`;

export default LandingIconStyled;